<template>
  <div>
    <el-form label-width="80px">
      <el-row :gutter="10">
        <el-col :lg="6"
                :md="8"
                :sm="8"
                :xl="8"
                :xs="12">
          <el-form-item label="批改时间:">
            <el-date-picker v-model="dates"
                            style="width:100%"
                            @focus="$forbid"
                            type="datetimerange"
                            :default-time="['00:00:00', '23:59:59']"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            range-separator="至"
                            start-placeholder="开始日期"
                            @change="search"
                            end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="0">
            <el-button type="primary"
                       @click="search">查询</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <CommenToUserForm ref="CommenToUserFormRef" />
    <Table ref="TablesRef"
           :tableData="tableData" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import CommenToUserForm from '../checTestPage/components/topic/components/evaluate.vue'
import { nowDateTime, frontDay } from '@/utils/date.js'

export default {
  data () {
    return {
      dates: [frontDay(31), nowDateTime()],
      tableData: [],
      showList: [],
      page: 1,
      size: 10,
      totalElements: 0,
      break: '',
      pageName: 'cz_changed_admin',
      tableColumns: [
        { prop: 'user_paper_id', align: 'center', label: '试卷ID', width: '', },
        { prop: 'subject_name', align: 'center', label: '科目名称', width: '', showToopic: true },
        { prop: 'module_name', align: 'center', label: '模块名称', width: '', },
        { prop: 'paper_score', align: 'center', label: '试卷总分', width: '', },
        { prop: 'user_score', align: 'center', label: '学生得分', width: '', },
        { prop: 'truename', align: 'center', label: '学生姓名', width: '', show: false },
        { prop: 'paper_comment', align: 'center', label: '试卷总评', width: '', },
        { prop: 'paper_time', align: 'center', label: '试卷提交时间', width: '170', },
        { prop: 'judge_end_time', align: 'center', label: '试卷批改时间', width: '170', },
        { prop: 'school_name', align: 'center', label: '所在学校', width: '', },
        {
          prop: 'd', align: 'center', label: '操作', width: '200', type: 'img', btns: [
            { content: '修改', type: 'edit', event: 'edit' },
            { content: '修改总评', type: 'editComment', event: 'editCommenToUser' },
            { content: '查看', type: 'look', event: 'toInfo' },
          ]
        },
      ]
    }
  },
  components: {
    CommenToUserForm
  },
  mounted () {
    this.setTablesColums()
  },
  methods: {
    editCommenToUser (row) {
      this.$refs.CommenToUserFormRef.getCommont(row.user_paper_id)
      this.$refs.CommenToUserFormRef.id = row.user_paper_id
      this.$refs.CommenToUserFormRef.dialogVisible = true
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      if (userInfo.is_parttime == 1) {
        this.tableColumns[5].show = true
        this.tableColumns[6].show = true
      }
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      let form = {
        timeBegin: this.dates ? this.dates[0] : '',
        timeEnd: this.dates ? this.dates[1] : '',
        page, limit
      }
      this.$http({
        url: '/api/v1/papers/my_checked',
        method: 'post',
        data: form
      }).then(res => {
        this.tableData = res.data.list
        for (var index = 0; index < this.tableData.length; index++) {
          this.tableData[index].isActive = true
        }
        this.totalElements = res.data.count
      })
    },
    cut (idx) {
      for (var index = 0; index < this.tableData.length; index++) {
        this.tableData[index].isActive = true
      }
      this.tableData[idx].isActive = false
      this.break = this.tableData[idx].paper_comment
      setTimeout(() => {
        this.$refs.table_line_ref.focus()
      }, 300);
    },
    save (index) {
      var obj = this.tableData[index]
      if (this.break == obj.paper_comment) {
        var obj = JSON.parse(JSON.stringify(this.tableData[index]))
        obj.isActive = true
        this.$set(this.tableData, index, obj)
        return
      }
      this.$http({
        url: '/api/v1/papers/evaluate',
        method: 'post',
        data: {
          user_paper_id: obj.user_paper_id,
          paper_comment: obj.paper_comment,
        }
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '修改成功!',
          type: 'success'
        });
        this.refresh()
      })
    },
    toInfo (row) {
      this.$router.push('/checkTestAll/checkTestPage?id=' + row.user_paper_id + '&judge_status=' + row.judge_status + '&isCheck=' + 1)
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    setList (list) {
      this.showList = list
    },
    edit (row) {
      console.log('row', row);
      this.$router.push('/checkTestAll/checkTestPage?id=' + row.user_paper_id + '&judge_status=' + row.judge_status)
    }
  }
}
</script>

<style lang="scss" scoped>
.table_line {
  width: 100%;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
</style>